import { Component, Vue } from "vue-property-decorator";

@Component
export default class TabMixin extends Vue {
  protected closeCurrentTab(): void {
    this.$root.$emit("on-close-tab", {
      id: this.$route.fullPath,
      name: this.$route.name,
    });
  }

  protected closeTab(id: string, name: string): void {
    this.$root.$emit("on-close-tab", {
      id: id,
      name: name,
    });
  }
}
